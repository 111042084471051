import React, { lazy } from 'react'

const ClaimApplicationForm = lazy(() =>
  import('./ClaimApplication/ClaimApplicationForm').then(module => ({
    default: module.ClaimApplicationForm,
  }))
)

const ClaimItemForm = lazy(() =>
  import('./ClaimApplication/ClaimItemForm').then(module => ({
    default: module.ClaimItemForm,
  }))
)

const ClaimApplicationDetail = lazy(() =>
  import('./ClaimApplication/ClaimApplicationDetail').then(module => ({
    default: module.ClaimApplicationDetail,
  }))
)

const ClaimItemDetail = lazy(() =>
  import('./ClaimApplication/ClaimItemDetail').then(module => ({
    default: module.ClaimItemDetail,
  }))
)

const ClaimPostingMonthListing = lazy(() =>
  import('./ClaimPosting/ClaimPostingMonthListing').then(module => ({
    default: module.ClaimPostingMonthListing,
  }))
)

const ClaimPaymentMonthListing = lazy(() =>
  import('./ClaimPayment/ClaimPaymentMonthListing').then(module => ({
    default: module.ClaimPaymentMonthListing,
  }))
)

const ApprovedClaimVerificationListing = lazy(() =>
  import('./ApprovedClaimVerification/ApprovedClaimVerificationListing').then(
    module => ({
      default: module.ApprovedClaimVerificationListing,
    })
  )
)

const ApprovedClaimVerificationDetails = lazy(() =>
  import('./ApprovedClaimVerification/ApprovedClaimVerificationDetails').then(
    module => ({
      default: module.ApprovedClaimVerificationDetails,
    })
  )
)

const ApprovedClaimVerificationForm = lazy(() =>
  import('./ApprovedClaimVerification/ApprovedClaimVerificationForm').then(
    module => ({
      default: module.ApprovedClaimVerificationForm,
    })
  )
)

const ClaimPostingListing = lazy(() =>
  import('./ClaimPosting/ClaimPostingListing').then(module => ({
    default: module.ClaimPostingListing,
  }))
)

const ClaimPostingDetail = lazy(() =>
  import('./ClaimPosting/ClaimPostingDetail').then(module => ({
    default: module.ClaimPostingDetail,
  }))
)

const ClaimPaymentListing = lazy(() =>
  import('./ClaimPayment/ClaimPaymentListing').then(module => ({
    default: module.ClaimPaymentListing,
  }))
)

const ClaimPaymentDetail = lazy(() =>
  import('./ClaimPayment/ClaimPaymentDetail').then(module => ({
    default: module.ClaimPaymentDetail,
  }))
)
const ClaimEntitlementByClaimType = lazy(() =>
  import('./ClaimEntitlement/ClaimEntitlementByClaimType').then(module => ({
    default: module.ClaimEntitlementByClaimType,
  }))
)

const ClaimEntitlementEmployeeListing = lazy(() =>
  import('./ClaimEntitlement/ClaimEntitlementEmployeeListing').then(module => ({
    default: module.ClaimEntitlementEmployeeListing,
  }))
)

const ClaimEntitlementByEmployee = lazy(() =>
  import('./ClaimEntitlement/ClaimEntitlementByEmployee').then(module => ({
    default: module.ClaimEntitlementByEmployee,
  }))
)
const ClaimApplication = lazy(() =>
  import('../ClaimModule/ClaimApplication/ClaimApplication').then(module => ({
    default: module.ClaimApplication,
  }))
)
const MileageLog = lazy(() => 
  import('../ClaimModule/MileageLog/MileageLogListing').then(module => ({
    default: module.MileageLogListing,
  }))
)
const ClaimMileageListing = lazy(() => 
  import('./MileageLog/ClaimMileageListing').then(module => ({
    default: module.ClaimMileageListing,
  }))
)
const ViewMileageLogGroup = lazy(() => 
  import('./MileageLog/ViewMileageLogGroup').then(module => ({
    default: module.ViewMileageLogGroup,
  }))
)
const AddMileageLog = lazy(() => 
  import('../ClaimModule/MileageLog/AddMileageLog').then(module => ({
    default: module.AddMileageLog,
  }))
)
const MyMileageLog = lazy(() => 
  import('../ClaimModule/MileageLog/MyMileageLog').then(module => ({
    default: module.MyMileageLog,
  }))
)

const ClaimAdjustment = lazy(() =>
  import('../ClaimModule/ClaimAdjustment/ClaimAdjustment').then(module => ({
    default: module.ClaimAdjustment,
  }))
)

const ClaimAdjustmentForm = lazy(() =>
  import('../ClaimModule/ClaimAdjustment/ClaimAdjustmentForm').then(module => ({
    default: module.ClaimAdjustmentForm,
  }))
)

const GLListing = lazy(() =>
  import('./GLExport/GLListing').then(module => ({
    default: module.GLListing,
  }))
)

const GLDetailsView = lazy(() =>
  import('./GLExport/GLDetailsView').then(module => ({
    default: module.GLDetailsView,
  }))
)

const ClaimGLProcessingListing = lazy(() =>
  import('./ClaimGLProcessing/ClaimGLProcessingListing').then(module => ({
    default: module.ClaimGLProcessingListing,
  }))
)

const ClaimGLProcessingDetailsView = lazy(() =>
  import('./ClaimGLProcessing/ClaimGLProcessingDetailsView').then(module => ({
    default: module.ClaimGLProcessingDetailsView,
  }))
)

const ClaimGLCycleListing = lazy(() =>
  import('./ClaimGLProcessing/ClaimGLCycleListing').then(module => ({
    default: module.ClaimGLCycleListing,
  }))
)

const claimRoutes = [
  {
    props: { exact: true, path: '/home/claimapplication' },
    component: <ClaimApplication />,
  },
  {
    props: { path: '/home/claimapplication/form/add' },
    component: <ClaimApplicationForm mode="add" />,
  },
  {
    props: { path: '/home/claimapplication/form/claimitem/add' },
    component: <ClaimItemForm mode="add" />,
  },
  {
    props: { path: '/home/claimapplication/form/:ClaimHeaderID/edit' },
    component: <ClaimApplicationForm mode="edit" />,
  },
  {
    props: {
      path: '/home/claimapplication/claimapplicationdetail/:ClaimHeaderID',
    },
    component: <ClaimApplicationDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/claimapplicationdetail/claimitemdetail',
    },
    component: <ClaimItemDetail />,
  },
  {
    props: { path: '/home/claimposting/monthlisting' },
    component: <ClaimPostingMonthListing />,
  },
  {
    props: { path: '/home/claimposting/posting', exact: true },
    component: <ClaimPostingListing />,
  },
  {
    props: { path: '/home/claimposting/posting/detail', exact: true },
    component: <ClaimPostingDetail />,
  },
  {
    props: { path: '/home/claimpayment/monthlisting' },
    component: <ClaimPaymentMonthListing />,
  },
  {
    props: { path: '/home/claimpayment/payment', exact: true },
    component: <ClaimPaymentListing />,
  },
  {
    props: { path: '/home/claimpayment/payment/detail', exact: true },
    component: <ClaimPaymentDetail />,
  },
  {
    props: { path: '/claims/approvedClaimVerificationListing' },
    component: <ApprovedClaimVerificationListing />,
  },
  {
    props: {
      path:
        '/claim/approvedClaimVerificationListing/ApprovedClaimVerificationDetails',
    },
    component: <ApprovedClaimVerificationDetails />,
  },
  // {
  //   props: {
  //     path:
  //       '/claims/approvedClaimVerificationListing/approvedClaimVerificationForm',
  //   },
  //   component: <ApprovedClaimVerificationForm />,
  // },
  {
    props: {
      path: '/claims/claimPostingListing',
    },
    component: <ClaimPostingListing />,
  },
  {
    props: { path: '/home/claimentitlement/byclaimtype' },
    component: <ClaimEntitlementByClaimType />,
  },
  {
    props: { path: '/home/claimentitlement/employeelisting' },
    component: <ClaimEntitlementEmployeeListing />,
  },
  {
    props: { path: '/home/claimentitlement/byemployee' },
    component: <ClaimEntitlementByEmployee />,
  },
  {
    props: { path: '/home/mileagelog' },
    component: <MileageLog />,
  },
  {
    props: { path: '/home/claimmileagelisting' },
    component: <ClaimMileageListing />,
  },
  {
    props: { path: '/home/viewmileageloggroup' },
    component: <  ViewMileageLogGroup
    />,
  },
  {
    props: { path: '/home/mileagelogform' },
    component: <AddMileageLog mode="add"/>,
  },
  {
    props: { path: '/home/mileagelogform/:MileageLogID/edit' },
    component: <AddMileageLog mode="edit"/>,
  },
  {
    props: { path: '/home/mymileagelog/:MileageLogID/' },
    component: <MyMileageLog/>,
  },
  {
    props: {
      exact: true,
      path: '/home/claimGLExport/GLListing',
    },
    component: <GLListing />,
  },
  {
    props: { exact: true, path: '/home/GLExport/GLListing/view' },
    component: <GLDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/home/GLProcessing/GLListing',
    },
    component: <ClaimGLProcessingListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/GLProcessing/GLListing/Details',
    },
    component: <ClaimGLProcessingDetailsView />,
  },
  {
    props: {
      exact: true,
      path: '/home/GLProcessing',
    },
    component: <ClaimGLCycleListing />,
  },
  {
    props: { exact: true, path: '/home/claimadjustment' },
    component: <ClaimAdjustment />,
  },
  {
    props: { path: '/home/claimadjustment/form/add' },
    component: <ClaimAdjustmentForm mode="add" />,
  },
]

export default claimRoutes
